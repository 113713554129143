"use client";

import React, { useState, useEffect } from "react";
import DataAnasayfa from "@/data/borsa-istanbul.json";
import Link from "next/link";
import "./HomeContainer.css";
import AdBanner from "@/components/AdBanner";
import AdBannerAI from "@/components/AdBannerAI";
import AdBannerTemelAnaliz from "@/components/AdBannerTemelAnaliz";

function HomeContainer() {
  const [displayedData, setDisplayedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChartType, setSelectedChartType] = useState("usd");
  const [isButtonRowVisible, setIsButtonRowVisible] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  const PUBLIC_URL = "";

  useEffect(() => {
    loadMoreData(3);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop <
          document.documentElement.offsetHeight - 100 ||
        isLoading
      )
        return;
      loadMoreData(3);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsButtonRowVisible(scrollY < lastScrollPosition || scrollY === 0);
      setLastScrollPosition(scrollY);

      // Sayfanın 300px aşağısına indikten sonra position fixed ekle
      if (scrollY > 300) {
        const buttonRow = document.querySelector(".button-row");
        buttonRow.classList.add("fixed");
      } else {
        const buttonRow = document.querySelector(".button-row");
        buttonRow.classList.remove("fixed"); // Scroll yukarı çıkınca stilini temizle
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollPosition]);

  const loadMoreData = (numberOfItems = 3) => {
    setIsLoading(true);

    let selectedData = DataAnasayfa.sort(() => 0.5 - Math.random())
      .slice(0, numberOfItems)
      .map((item) => {
        return { ...item, Link: item.Link || "#" };
      });

    setDisplayedData((prevData) => [...prevData, ...selectedData]);

    setIsLoading(false);
  };

  const getImagePath = (kod, type) => {
    switch (type) {
      case "teknik":
        return `/images/${kod}-hedef-fiyat-grafik.webp`;
      case "yapayzeka":
        return `/images/${kod}-tahmin-yorum.webp`;
      default:
        return `/images/${kod}.webp`;
    }
  };
  const logoUrl = "/assets/favicon.ico";
  const defaultImage = logoUrl;

  return (
    <div>
      <div className={`button-row ${isButtonRowVisible ? "visible" : ""}`}>
        <button
          onClick={() => setSelectedChartType("usd")}
          className="autoScaleButton"
        >
          Hisse USD Fiyatı
        </button>
        <button
          className="autoScaleButton"
          onClick={() => setSelectedChartType("teknik")}
        >
          Teknik Analiz
        </button>
        <button
          className="autoScaleButton"
          onClick={() => setSelectedChartType("yapayzeka")}
        >
          Yapay Zeka Tahmini
        </button>
      </div>
      {isLoading && <p>Yükleniyor...</p>}
      <div>
        {displayedData.map((data, index) => (
          <React.Fragment key={data.Kod}>
            <div className="image-anasayfa">
              <Link
                href={`${PUBLIC_URL}/${data.Link}`}
                title={`Detaylar için tıklayın: ${data.Kod}`}
                target="_blank"
                passHref
              >
                <img
                  src={getImagePath(data.Kod, selectedChartType)}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = defaultImage;
                  }} // Resim yüklenemediğinde varsayılan resme geçiş
                  alt={`${data.Kod} ${
                    selectedChartType === "usd"
                      ? "Dolar Bazında Güncel Fiyat Grafiği"
                      : selectedChartType === "teknik"
                      ? "Detaylı Teknik Analiz Grafiği"
                      : "Yapay Zeka Destekli Fiyat Tahmin Grafiği"
                  }`}
                />
                <p>
                  <span className="hisseYorumSpan">
                    <strong>{data.Kod}</strong>{" "}
                    {selectedChartType === "usd"
                      ? "Tarihsel USD Fiyat Grafiği"
                      : selectedChartType === "teknik"
                      ? "Teknik Analiz İncelemesi"
                      : "Yapay Zeka Fiyat Tahmini"}
                  </span>
                </p>
              </Link>
            </div>
            {/* Her üç öğeden sonra reklam ekleyin */}
            {(index + 2) % 9 === 0 && <AdBanner />}
            {(index - 1) % 9 === 0 && <AdBannerAI />}
            {(index + 5) % 9 === 0 && <AdBannerTemelAnaliz />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
export default HomeContainer;
