"use client";
import React from "react";
import { useAds } from "@/components/AdsProvider";

const AdBanner = () => {
  const { showAds } = useAds();

  if (!showAds) {
    return null; // Eğer reklam gösterimi pasifse hiçbir şey gösterme
  }

  return (
    <div className="reklam-container">
      <a
        href="/yapay-zeka-borsa-oneri-tablolari"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <img
          src="/assets/reklam3.jpg"
          alt="Reklam"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </a>
    </div>
  );
};

export default AdBanner;
