"use client";
import React, { createContext, useState, useContext } from "react";

const AdsContext = createContext({
  showAds: true, // Default değerler eklenir
  setShowAds: () => {}, // Boş fonksiyon olarak eklenir
});

export const useAds = () => useContext(AdsContext);

export const AdsProvider = ({ children }) => {
  const [showAds, setShowAds] = useState(true);

  return (
    <AdsContext.Provider value={{ showAds, setShowAds }}>
      {children}
    </AdsContext.Provider>
  );
};
